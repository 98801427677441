<template>
	<div id="imprint">

	<h1>Imprint / Impressum</h1>

	<br>
	
	<p>Markus Ebbighausen<br>
	Dipl. Informatiker (FH)<br>
	79539 Lörrach<br>
	Germany<br><br>
	markus[at]flimmerwerk.de<br>
	www.flimmerwerk.de</p>
	<br>

	<h2>Disclaimer / Haftungsausschluss</h2>
	<div class="buttons">(
		<div class="lang-btn" 
			:class="{ 'lang-btn-active': language == 'en' }"
			v-on:click="showLang('en')">English
		</div> |
		<div class="lang-btn" 
			:class="{ 'lang-btn-active': language == 'de' }"
			v-on:click="showLang('de')">Deutsch
		</div>)
	</div>

	<div v-bind:key="topic.index" v-for="topic in topics">
		<h4>{{getTitle(topic)}}</h4>
		<p>{{getText(topic)}}</p>
	</div>

	<div class="legal">
		Translations of this imprint made with <a href="https://www.deepl.com/de/translator" target="_blank">DeepL</a>.
	</div>


	</div>
</template>

<script>
import jsonImprint from "@/assets/imprint.json";

export default {
	name: 'Imprint',
	data() {
		return {
			topics: jsonImprint, 
			language: 'en'
		}
	},
	methods: {
		showLang(lang) {
			this.language = lang;
		},
		getTitle(topic) {
			return (this.language === 'de') ? (topic.index + '. ' + topic.title.de) : (topic.index + '. ' + topic.title.en);
		},
		getText(topic) {
			return (this.language === 'de') ? topic.text.de : topic.text.en;
		}
	}, 
	created() {
		//
	}
}
</script>

<style scoped>

.buttons {
	display: flex;
}

.lang-btn {
	color: #999;
	text-decoration: underline;
	cursor: pointer;
}
.lang-btn:hover {
	color: #fff;
	text-decoration: none;
	background-color: #333;
}
.lang-btn-active {
	color: #333;
	text-decoration: none;
	cursor: default;
}

.lang-btn-active:hover {
	color: #333;
	background-color: white;
}

.legal {
	margin-top: 25px;
}
</style>